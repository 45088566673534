import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
new Splide('.carouselSale__splide', {
    lazyLoad: 'nearby',
    focus: 0,
    omitEnd: true,
    perPage: 3,
    perMove: 3,
    gap: '4%',
    pagination: false,
    breakpoints: {
        950: { perPage: 2, perMove: 2 },
        700: { perPage: 1, perMove: 1 }
    }
}).mount();
